.evaluation-container {
    padding: 20px;
    background-color: #212735;
    color: #e0e0e0;
    margin: 0 auto;
    width: calc(100% - 40px); /* Add padding to prevent the table from touching the edges */
    box-sizing: border-box;
  }
  
  .ag-theme-quartz-dark .ag-cell {
    white-space: normal !important;
    line-height: 1.4 !important; /* Adjust line height as needed */
    word-wrap: break-word;
    overflow-wrap: break-word;
}
  
  .evaluation-container h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .confidence-level-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .confidence-level-container label {
    margin-right: 10px;
    font-size: 1.1em;
  }
  
  .confidence-level-container select,
  .confidence-level-container input[type="range"] {
    padding: 5px 10px;
    font-size: 1.1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #212735;
    color: #e0e0e0;
  }
  
  .summary-grid {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .summary-grid > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  