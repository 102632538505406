body {
  background-color: #212735; /* Set the body background color to match the app theme */
  color: #e0e0e0;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  background-color: #212735;
  color: #e0e0e0;
}

.App-header {
  background-color: #212735;
  padding: 20px 20px; /* Increase top and bottom padding */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap; /* Allow header to wrap */
}

.header-content {
  display: flex;
  flex-wrap: wrap; /* Allow the content to wrap */
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: space-between; /* Distribute space between items */
}

.header-title {
  font-size: 2.5em; /* Increase the font size */
  font-weight: bold;
  padding: 20px 0; /* Add spacing above and below */
  flex: 1 1 auto; /* Allow title to be flexible */
}

nav {
  display: flex;
  gap: 15px;
  flex-wrap: wrap; /* Allow nav links to wrap */
  justify-content: flex-end; /* Align nav links to the right */
  flex: 1 1 auto; /* Allow nav to be flexible */
}

nav a {
  color: #a0a0a0; /* Change to a lighter grey for better contrast */
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

nav a:hover {
  background-color: #3a3f51;
  color: #ffffff;
}

nav a.active {
  background-color: #61dafb;
  color: #212735;
}

.content {
  width: 100%;
  box-sizing: border-box;
}

.ag-theme-quartz-dark {
  font-size: 14px;
  text-align: left;
}

.auto-height-cell {
  white-space: normal;
  line-height: 1.4;
}

.ag-cell a {
  color: #61dafb;
  text-decoration: none;
}

.ag-cell a:hover {
  text-decoration: underline;
}

.cell-true {
  background-color: #42A5F5;
  color: white;
}

.cell-false {
  background-color: #BA68C8;
  color: white;
}

.ag-header-cell {
  white-space: normal !important;
  padding: 4px;
  height: auto;
}

.ag-header-cell-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: normal !important;
}

.ag-header-cell a {
  word-break: break-all;
  color: #61dafb;
}

.ag-header-cell a:hover {
  text-decoration: underline;
}

/* Recommendation */

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.checkbox-group,
.radio-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

button {
  border: 1px solid #ccc;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: white;
  border-radius: 4px;
  transition: background-color 0.1s ease, border-color 0.1s ease;
  color: #212735; 
}

button.selected {
  background-color: lightblue;
  border-color: #007BFF;
  color: #212735; 
}

button:hover:not(.selected) {
  background-color: #f0f0f0;
}

button.selected:hover {
  background-color: lightblue;
}

button.info-button {
  background-color: #61dafb;
  color: #212735;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #e0e0e0; 
  margin: 0 10px;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: #212735; 
}

input[type="radio"]:checked::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #007BFF;
  position: absolute;
  top: 2px;
  left: 2px;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.home-page {
  text-align: center;
  padding: 20px;
}

.home-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.home-links div {
  background-color: #3a3f51;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
}

.home-links a {
  color: #a0a0a0; /* Different color for other links */
  text-decoration: none;
  transition: color 0.3s ease;
}

.home-links a:hover {
  color: #ffffff;
}

.home-links a.download-link {
  color: #61dafb; /* Specific color for the "Download Dataset" link */
}

.home-links h3 {
  margin-top: 0;
}

.header-link {
  color: #61dafb; /* Same color for the "Download Dataset" link */
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: inline-block; /* Ensure it aligns with other links */
  margin-left: 15px; /* Add margin to the left */
}

.header-link:hover {
  background-color: #3a3f51;
  color: #ffffff;
}
